// Fund Picker Controller Class
;(function()
{
	"use strict";
	var fundPickerControllerClass = function()
	{
		$(document).ready(function()
		{
			var $doc = $(this);

			var riskMatrix 		= getRiskMatrix();
			var fundLookupList 	= getFundLookupList();


			$doc.on("getInvestmentFund", function(e, p)
			{
				setDefaultParamenters();

				var validResults = findFunds(p.values.riskLevel, p.values.investTerm);

				p.callback.isValid(validResults);
				
				function setDefaultParamenters()
				{
					// Default Values
					p.values.riskLevel 	= p.values.riskLevel || "";
					p.values.investTerm	= p.values.investTerm || "";

					// Callback Methods
					p.callback.isValid = p.callback.isValid || function() {};
					p.callback.isInvalid = p.callback.isInvalid || function() {};
				}
			});

			$doc.on("getFilterDataByRiskLevel", function(e, p)
			{
				p.callback.onSuccess = p.callback.onSuccess || function() {};
				p.callback.onSuccess(investTermLimitOnRiskLevel(p.riskLevel));
			});

			$doc.on("getFilterDataByInvestTerm", function(e, p)
			{
				p.callback.onSuccess = p.callback.onSuccess || function() {};
				p.callback.onSuccess(riskLevelLimitOnInvestTerm(p.investTerm));
			});

			function getRiskMatrix()
			{
				return {
					0 : {
						riskAlias    : "Low",
						riskName     : "low",
						allowedTerms : ["1", "1-3"]
					},

					1 : {
						riskAlias    : "Low-Medium",
						riskName     : "low-med",
						allowedTerms : ["1-3"]
					},

					2 : {
						riskAlias    : "Medium",
						riskName     : "med",
                        allowedTerms: ["3-5"]
					},

					3 : {
						riskAlias 	 : "Medium-High",
						riskName     : "med-high",
						allowedTerms : ["5+"]
					},

					4 : {
						riskAlias    : "High",
						riskName     : "high",
						allowedTerms : ["7+"]
					}
				};
			}


			function getFundLookupList()
			{
				return [
					{
						fundName : "Money Market Fund",
						fundCode : "PMMF",
						criteria : {
							riskByName : "low",
							allowedTerms : "1"
						}
                    },
                    {
                        fundName: "Prudential Income Fund",
                        fundCode: "PIFCA",
                        criteria: {
                            riskByName: "low",
                            allowedTerms: "1-3"
                        }
                    },

                    {
                        fundName: "Enhanced Income Fund",
                        fundCode: "PEICA",
                        criteria: {
                            riskByName: "low-med",
                            allowedTerms: "1-3"
                        }
                    },

                    {
                        fundName: "Inflation Plus Fund",
                        fundCode: "PRIP",
                        criteria: {
                            riskByName: "med",
                            allowedTerms: "3-5"
                        }
                    },

					{
						fundName : "Balanced Fund",
						fundCode : "PRUA",
						criteria : {
							riskByName : "med-high",
							allowedTerms : "5+"
						}
					},

					{
						fundName : "Dividend Maximiser Fund",
						fundCode : "PRUM",
						criteria : {
							riskByName : "high",
							allowedTerms : "7+"
						}
					},

					{
						fundName : "Equity Fund",
						fundCode : "PRUO",
						criteria : {
							riskByName : "high",
							allowedTerms : "7+"
						}
					},
				];
			}

			function findFunds(riskLevel, investTerm)
			{
				var result = {};
				var investTermArr = investTerm.split(",");

				var i;
				for (i in investTermArr)
				{
					if (investTermArr[i].hasOwnProperty)
					{
						$.extend(true, result, lookupFund(riskLevel, investTermArr[i]));
					}
				}
				sendFacebookToolCompletedEvent('Fund Selector');
				return result;


				function lookupFund(riskLevel, investTerm)
				{
					var lookupResult = {};

					for (i in fundLookupList)
					{
						if (fundLookupList[i].hasOwnProperty)
						{
							var cFund = fundLookupList[i];
							
							if (cFund.criteria.riskByName === riskLevel && cFund.criteria.allowedTerms === investTerm)
							{
								lookupResult[i] = {};

								lookupResult[i] = {
									fundName : cFund.fundName,
									fundCode : cFund.fundCode
								};
							}
						}
					}

					return lookupResult;
				}
			}


			function riskLevelLimitOnInvestTerm(investTerm)
			{
				var filterResult = [];
				var investTermArr = investTerm.split(",");
				
				var IN_ARRAY_FALSE = -1;

				for (var i in investTermArr)
				{
					if (investTermArr[i].hasOwnProperty)
					{
						getFilterResult(investTermArr[i]);
					}
				}
				return filterResult;

				function getFilterResult(investTerm)
				{
					for (var i in riskMatrix)
					{
						if (riskMatrix[i].hasOwnProperty)
						{
							if ($.inArray(investTerm, riskMatrix[i].allowedTerms) !== IN_ARRAY_FALSE)
							{
								if ($.inArray(riskMatrix[i].riskName, filterResult) === IN_ARRAY_FALSE)
								{
									filterResult.push(riskMatrix[i].riskName);
								}
							}
						}
					}
				}
			}


			function investTermLimitOnRiskLevel(riskLevel)
			{
				var filterResult;
				for (var i in riskMatrix)
				{
					if (riskMatrix[i].hasOwnProperty)
					{
						if (riskMatrix[i].riskName === riskLevel)
						{
							filterResult = riskMatrix[i].allowedTerms;
						}
					}
				}

				return filterResult;
			}
		});
	};

	window.FundPicker = new fundPickerControllerClass();
})();