import "/src/css/legacy/fundpicker.css";
import "/src/css/legacy/funds.css";

import "/src/js/legacy/fundpicker-tool.js";
import "/src/js/legacy/tools-global.js";

(function () {
	"use strict";

	$(document).ready(function () {
		var $doc = $(this);

		var filterFirstOrder = null;
		var userHasSelected = {
			riskLevel: function () {
				var $riskLevelRow = $(".fund-picker-controls.risk-level");
				var result = $riskLevelRow.children(".active").length > 0;

				return result;
			},
			investTerm: function () {
				var $riskLevelRow = $(".fund-picker-controls.investment-term");
				var result = $riskLevelRow.children(".active").length > 0;

				return result;
			}
		};

		function applyUIFilters(trigger) {
			var data, filterResult;
			var $activeInvestTermButton = $(".invest-term-button.active");
			var $activeRiskButton = $(".risk-button.active");

			var filterFunctions = {
				filterByRisk: function () {
					if (filterFirstOrder === "risk") {
						data = $activeRiskButton.attr("data-risk-name");
						filterResult =
							eventTriggers.getFilterDataByRiskLevel(data);

						if (trigger === "risk") {
							$activeInvestTermButton.removeClass("active");
						}
					}
				},

				filterByInvestTerm: function () {
					if (filterFirstOrder === "term") {
						data = $activeInvestTermButton.attr("data-term");
						filterResult =
							eventTriggers.getFilterDataByInvestTerm(data);

						if (trigger === "term") {
							$activeRiskButton.removeClass("active");
						}
					}
				},

				toggleFindFunds: function () {
					if (
						userHasSelected.riskLevel() &&
						userHasSelected.investTerm()
					) {
						$(".lookup-funds").attr("disabled", false);
					} else {
						$(".lookup-funds").attr("disabled", true);
					}
				}
			};

			for (var i in filterFunctions) {
				if (filterFunctions[i].hasOwnProperty) {
					filterFunctions[i]();
				}
			}
		}

		function plot(graphData, targetElem) {
			var chart = AmCharts.makeChart(targetElem, {
				zoomOutText: "",
				type: "serial",
				categoryField: "date",
				dataDateFormat: "YYYY-MM-DD",
				backgroundColor: "#fff",
				color: "#2f2f2f",
				fontFamily: "InfraSemibold",
				fontSize: 12,
				theme: "dark",
				categoryAxis: {
					parseDates: true,
					dashLength: 1,
					minorGridEnabled: true
				},

				chartCursor: {
					enabled: true,
					cursorAlpha: 0,
					valueLineAlpha: 0.2,
					valueLineEnabled: true,
					zoomable: false
				},
				numberFormatter: {
					precision: 2,
					decimalSeparator: ".",
					thousandsSeparator: ","
				},
				trendLines: [],
				graphs: [
					{
						balloonText:
							"<div style='font-size:14px; color:#2f2f2f;'><strong><span style='color:#2f2f2f'>[[title]]</span> [[value]]</strong></div>",
						columnWidth: 0,
						cornerRadiusTop: 47,
						fixedColumnWidth: 0,
						fontSize: 0,
						gapPeriod: 1,
						id: "g1",
						legendAlpha: 1,
						lineColor: "#055a60",
						lineThickness: 2,
						markerType: "circle",
						negativeFillAlphas: 0,
						negativeLineAlpha: 0,
						title: "Fund Return",
						useLineColorForBulletBorder: true,
						valueField: "value"
					}
				],
				guides: [],
				valueAxes: [
					{
						id: "v1",
						axisAlpha: 0
					}
				],

				allLabels: [],
				balloon: {
					borderThickness: 2,
					fillAlpha: 1,
					shadowAlpha: 0,
					horizontalPadding: 10,
					verticalPadding: 8
				},

				legend: {
					enabled: true,
					autoMargins: false,
					left: 50,
					bottom: 0,
					marginLeft: 15,
					marginRight: 5,
					marginBottom: 10,
					position: "bottom",
					valueWidth: 50,
					verticalGap: 5,
					color: "#2f2f2f"
				},

				responsive: {
					enabled: true,
					rules: [
						// at 400px wide, we hide legend
						{
							maxWidth: 700,
							overrides: {
								chartScrollbar: {
									enabled: false
								}
							}
						}
					]
				},

				titles: [],

				dataProvider: graphData[0],
				export: {
					enabled: true
				}
			});

			var yAxis2 = new window.AmCharts.ValueAxis();
			yAxis2.position = "left"; // set this to "right" if you want it on the right
			yAxis2.offset = 30;
			chart.addValueAxis(yAxis2);

			// graph #2
			var graph2 = new window.AmCharts.AmGraph();
			graph2.lineColor = "#65b4e5";
			graph2.lineThickness = 2;
			graph2.balloonText =
				"<div style='font-size:14px; color:#2f2f2f;'><strong><span style='color:#2f2f2f'>[[title]]</span> [[value]]</strong></div>";
			graph2.xField = "bx";
			graph2.yField = "by";
			graph2.dataProvider = graphData[1];
			graph2.valueField = "value";
			graph2.yAxis = yAxis2; // we need to assign at least one graph to a value axis or it's not shown
			graph2.title = "Benchmark";
			graph2.markerType = "circle";
			graph2.legendAlpha = 1;

			chart.addGraph(graph2);
			//$("a[href='http://www.amcharts.com/javascript-charts/']").hide();
		}

		// Resizes the graph on print
		(function () {
			var beforePrint = function () {
				for (var i = 0; i < window.AmCharts.charts.length; i++) {
					var chart = AmCharts.charts[i];
					console.log(AmCharts.charts);
					chart.div.style.width = "400px";
					chart.div.style.height = "428px";
					chart.validateNow();
				}
			};
			var afterPrint = function () {
				for (var i = 0; i < window.AmCharts.charts.length; i++) {
					var chart = AmCharts.charts[i];
					chart.div.style.width = "100%";
					chart.div.style.height = "439px";
					chart.validateNow();
				}
			};

			if (window.matchMedia) {
				var mediaQueryList = window.matchMedia("print");
				mediaQueryList.addListener(function (mql) {
					if (mql.matches) {
						beforePrint();
					} else {
						afterPrint();
					}
				});
			}

			window.onbeforeprint = beforePrint;
			window.onafterprint = afterPrint;
		})();

		var f = {
			riskLevelEvents: function () {
				$doc.on("click", ".risk-button", function () {
					var $this = $(this);

					$(".risk-button").removeClass("active");
					$this.addClass("active");

					if (filterFirstOrder === null) {
						filterFirstOrder = "risk";
					}

					applyUIFilters("risk");
				});
			},

			investTermEvents: function () {
				$doc.on("click", ".invest-term-button", function () {
					var $this = $(this);

					$(".invest-term-button").removeClass("active");
					$this.addClass("active");

					if (filterFirstOrder === null) {
						filterFirstOrder = "term";
					}

					applyUIFilters("term");
				});
			},

			resetEvents: function () {
				$doc.on("click", ".reset", function () {
					filterFirstOrder = null;
					var $fundGraphBlock = $(".fund-graph-block");

					$(".invest-term-button, .risk-button")
						.removeClass("active")
						.attr("disabled", false);

					$fundGraphBlock.removeClass("show-chart-block");
					$fundGraphBlock.children(".show").removeClass("show");

					applyUIFilters();
				});
			},

			findFunds: function () {
				$doc.on("click", ".lookup-funds", function () {
					var riskLevel = $(".risk-button.active").attr(
						"data-risk-name"
					);
					var investTerm = $(".invest-term-button.active").attr(
						"data-term"
					);

					eventTriggers.getInvestmentFund(riskLevel, investTerm);
				});
			},

			checkForPreSelectedOptions: function () {
				var risk = getParameterByName("risk");
				var term = getParameterByName("term");

				if (risk !== null && term !== null) {
					$(".fpbutton[data-risk-name='" + risk + "']").click();
					$(".fpbutton[data-term='" + term + "']").click();

					setTimeout(function () {
						$(".lookup-funds").click();
					}, 250);
				}

				function getParameterByName(name, url) {
					if (!url) {
						url = window.location.href;
					}

					name = name.replace(/[\[\]]/g, "\\$&");

					var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
						results = regex.exec(url);

					if (!results) {
						return null;
					}

					if (!results[2]) {
						return "";
					}

					return decodeURIComponent(results[2]);
				}
			}
		};

		var eventTriggers = {
			getInvestmentFund: function (riskLevel, investTerm) {
				$doc.trigger("getInvestmentFund", {
					values: {
						riskLevel: riskLevel,
						investTerm: investTerm
					},

					callback: {
						isValid: function (data) {
							// Redirect user to Fund Picker tool page, if using Home Page Fund Picker
							if ($(".wrapper").hasClass("homepage")) {
								redirectToFund();
								return;
							}

							// Normal callback functionality
							var $fundGraphBlock = $(".fund-graph-block");

							$fundGraphBlock.removeClass("show-chart-block");

							setTimeout(function () {
								$fundGraphBlock
									.children(".show")
									.removeClass("show");

								for (var i in data) {
									if (data[i].hasOwnProperty) {
										getFundData(data[i].fundCode);
									}
								}
							}, 800);

							function push(date, value, data) {
								data.push({
									date: new Date(date),
									value: value
								});

								return data;
							}

							function getFundData(fundCode) {
								var chartData = [];
								var graphData = [];

								$.ajax({
									type: "GET",
									url: "/api/Services/GetGraphData",
									data: {
										fundCode: fundCode,
										period: "doesnt matter"
									},
									success: function (response) {
										var $fundDetailContainer = $(
											".fund-block." + fundCode
										);

										if (
											!$fundDetailContainer.hasClass(
												"hasChart"
											)
										) {
											generateFundGraph();
											$fundDetailContainer.addClass(
												"hasChart"
											);
										}

										$fundDetailContainer.addClass("show");
										$fundGraphBlock.addClass(
											"show-chart-block"
										);

										function generateFundGraph() {
											$.each(
												response.FundReturnGraphData,
												function (k, v) {
													var dateArr = k.split("-");
													k =
														dateArr[2] +
														"-" +
														(dateArr[1].length == 1
															? "0" + dateArr[1]
															: dateArr[1]) +
														"-" +
														(dateArr[0].length == 1
															? "0" + dateArr[0]
															: dateArr[0]);

													graphData = push(
														new Date(k),
														v,
														graphData
													);
												}
											);

											chartData.push(graphData);
											graphData = [];

											$.each(
												response.BenchmarkReturnGraphData,
												function (k, v) {
													var dateArr = k.split("-");
													k =
														dateArr[2] +
														"-" +
														(dateArr[1].length == 1
															? "0" + dateArr[1]
															: dateArr[1]) +
														"-" +
														(dateArr[0].length == 1
															? "0" + dateArr[0]
															: dateArr[0]);

													graphData = push(
														new Date(k),
														v,
														graphData
													);
												}
											);

											chartData.push(graphData);
											graphData = [];

											var chartdiv =
												$fundDetailContainer.find(
													".chartdiv"
												)[0];
											plot(chartData, chartdiv);
										}
									}
								});
							}

							/*function applyChartHTML(fundCode)
							{
								var html = "";

								html += "<div class='chartdiv " + fundCode +"'>";
								html += "&nbsp;";
								html += "</div>";

								return html;
							}*/

							function redirectToFund() {
								var baseURL = window.fundpickerRedirectBaseURL;
								var getAppend =
									"?risk=" +
									riskLevel +
									"&term=" +
									investTerm;

								window.location.replace(baseURL + getAppend);
							}
						},

						isInvalid: function (data) {
							window.console.warn(data);
						}
					}
				});
			},

			getFilterDataByRiskLevel: function (riskLevel) {
				$doc.trigger("getFilterDataByRiskLevel", {
					riskLevel: riskLevel,
					callback: {
						onSuccess: function (data) {
							var $investTermButton = $(".invest-term-button");

							$investTermButton.attr("disabled", true);
							for (var i in data) {
								if (data[i].hasOwnProperty) {
									$(
										".invest-term-button[data-term='" +
											data[i] +
											"']"
									).attr("disabled", false);
								}
							}
						}
					}
				});
			},

			getFilterDataByInvestTerm: function (investTerm) {
				$doc.trigger("getFilterDataByInvestTerm", {
					investTerm: investTerm,
					callback: {
						onSuccess: function (data) {
							var $riskButton = $(".risk-button");

							$riskButton.attr("disabled", true);
							for (var i in data) {
								if (data[i].hasOwnProperty) {
									$(
										".risk-button[data-risk-name='" +
											data[i] +
											"']"
									).attr("disabled", false);
								}
							}
						}
					}
				});
			}
		};

		for (var i in f) {
			if (f[i].hasOwnProperty) {
				f[i]();
			}
		}
	});
})();
