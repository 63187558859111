/*
 * We will use a self executing/anonomous function to protect the private variables and methods.
 * We also add the ; incase a the previous code has not been closed off when we concatenate our source code later for production.
 */
;(function() {

	"use strict";
	
	$(document).ready(function() {

		var $doc = $(this),
			qna = window._QnA;
		
		if (!qna) {

			applyToolGlobalCode();

		} else {

			var w_qnaToolTypeCheck = setInterval(function() {

				if (getToolType()) {

					applyToolGlobalCode();
					clearInterval(w_qnaToolTypeCheck);

				}
			}, 200);

		}

        function getToolType() {

            var toolType =  null;

            if(qna) {

				toolType = qna.getCurrentToolType();

            } else {

				toolType = window.toolType;

            }

            return toolType;
        }

		function applyToolGlobalCode() {

			$doc.on("keypress", function(e) {

				var keycodes = {
						enter : 13
					},
					isQnaActive;

				if(qna) {

					isQnaActive = qna.isActive();

				}

				function keyCheckHandler() {

					if (e.keyCode === keycodes.enter) {

						if (isQnaActive) {

							qnaControlHandler();

						} else {

						toolControlHandler(getToolType());

						}
					}

				}

				keyCheckHandler();

			});

			function qnaControlHandler() {

				$(".question.show .next.enabled").click();

			}

			function toolControlHandler(tooltype) {

				var tooltypeList = {

					"retirement-calc" : function() {
						// Currently we don't use this but it is here for future usages.
					},

					"goal-calc" : function() {
						$(".calculate").click();
                    },

                    "education-calc": function () {
                        $(".calculate").click();
                    },

                    "tax-free" : function() {
                        $(".calculate").click();
                    },

					"fund-picker" : function() {
						$(".lookup-funds").click();
					}
				};

				tooltypeList[tooltype]();

			}

		}
		// END: applyToolGlobalCode();

	});
	// End : Document.ready();

})();
