;(function () {
    "use strict";
    //global
    var fundId = '';
    var fundName = '';
    var chartData = [];
    var graphData = [];
    var chart;
   var  data = [];
    // grab an element
    var header = document.querySelector("header");
    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(header, {
        tolerance: {
            down: 0,
            up: 10
        },
        offset: 150,

    }).init();






    $(document).ready(function () {
        function populateGraphData(period, targetElem) {
            if(targetElem){
                chartData = [];
                graphData = [];
                var fundId = $(targetElem).parent().parent().parent().find('.chartdiv').attr('data-performance');


                $.ajax({
                    url: "/api/services/GetGraphData?fundCode=" + fundId + "&period=" + period,
                    type: "GET",
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {

                        $.each(response.FundReturnGraphData, function (k, v) {
                            var dateArr = k.split('-');
                            k = dateArr[2] + '-' + (dateArr[1].length == 1 ? '0' + dateArr[1] : dateArr[1]) + '-' + (dateArr[0].length == 1 ? '0' + dateArr[0] : dateArr[0]);

                            graphData = push(new Date(k), v, graphData);

                        });

                        chartData.push(graphData);
                        graphData = [];

                        $.each(response.BenchmarkReturnGraphData, function (k, v) {
                            var dateArr = k.split('-');
                            k = dateArr[2] + '-' + (dateArr[1].length == 1 ? '0' + dateArr[1] : dateArr[1]) + '-' + (dateArr[0].length == 1 ? '0' + dateArr[0] : dateArr[0]);

                            graphData = push(new Date(k), v, graphData);

                        });

                        chartData.push(graphData);
                        graphData = [];

                        plot(chartData, targetElem);

                        var bottomBar = $(targetElem).siblings('.fund-graph-bottom-bar');

                        var fundReturn = bottomBar.find('.fundReturnStats');
                        //var ObjectiveReturn = bottomBar.find('.ObjectiveReturnStats');
                        var benchmarkReturn = bottomBar.find('.benchmarkReturnStats');
                        var outPerformance = bottomBar.find('.outperformanceReturnStats');
                        var dailyPrice = bottomBar.find('.dailyReturnStats');

                        if (response.Statistics !== null) {
                            fundReturn.html(response.Statistics.FundReturn);
                            benchmarkReturn.html(response.Statistics.BenchmarkReturn);
                            //ObjectiveReturn.html(response.Statistics.ObjectiveReturn);
                            outPerformance.html(response.Statistics.OutPerformance);
                            dailyPrice.html(response.Statistics.DailyPrice);
                        }
                    }
                });
            }
        };

        $('.graph-toggle').click(function (e) {

            var fund = e.target.closest('fund');
            var graph = $(this).parent().parent().children(".chartdiv");

            fundId = graph.attr("data-performance");
            fundName = graph.attr("data-fundName");

            var $this = $(this);
            var graphPeriod = $this.attr("data-value");

            $this.siblings(".graph-toggle").removeClass("graph-selected-period");
            $this.addClass("graph-selected-period");
            populateGraphData(graphPeriod, graph[0]);

            data = [];
            masterData = [];

        });

        function GetGraphData(e,data) {

            var fund = e.target.closest('.fund');

            var $this = $(data);
            var name = $this.text();

            var defaultPeriod = $this.closest('.fund').find('.graph-selected-period').attr('data-value');

            var trimmedName = name.substring(0, name.indexOf('('));

            var graph = $this.parents(".fund-header").parent().find('.chartdiv');

            fundId = graph.attr("data-performance");
            fundName = graph.attr("data-fundName");

            $this.parentsUntil('.fund').next('.fund-content').slideToggle(250);
            $this.next(".fund-links").children(".quicklook-icon").toggleClass("rotate");
            if(!fund.classList.contains("hasOpened")){
                setTimeout(() => {populateGraphData(defaultPeriod, graph[0]);}, 250)
                fund.classList.add("hasOpened")
            }

            /* Adds top border to div under expanded fund */
            
            $this.parentsUntil(".fund-list ul").next(".fund").children(".fund-header").toggleClass("fund-header-border");
            $this.parentsUntil(".fund-list ul").children(".fund-header").toggleClass("fund-header-expanded-state");
            var actualPriceID = $this.parents(".fund-header").siblings(".fund-content").children(".fund-content-right").children(".chartdiv").attr('value');
        };

        $(".fund-quicklook").click(function (e) {
            var elem = this;
            GetGraphData(e, elem);
        });

        //var graph = defaultPeriod.parent().parent().children("#chartdiv");
        var graph = $("#chartdiv");
   
        var defaultPeriod = $('.graph-selected-period');

        populateGraphData(defaultPeriod.attr('data-value'), graph[0]);

    });

    function plot(graphData, targetElem) {
        if (graphData.length > 0) {
            chart = AmCharts.makeChart(targetElem, {
                "zoomOutText": "",
                "type": "serial",
                "categoryField": "date",
                "dataDateFormat": "YYYY-MM-DD",
                "backgroundColor": "#fff",
                "color": "rgb(74, 74, 74)",
                "fontFamily": 'InfraSemibold',
                "fontSize": 12,
                "theme": "dark",
                "categoryAxis": {
                    "parseDates": true,
                    "dashLength": 1,
                    "minorGridEnabled": true
                },

                "chartCursor": {
                    "enabled": true,
                    "cursorAlpha": 0.2,
                    "valueLineAlpha": 0.0,
                    "valueLineEnabled": false,
                    "zoomable": false
                },
                "numberFormatter": {
                    "precision": 2, "decimalSeparator": '.', "thousandsSeparator": ','
                },
                "trendLines": [],
                "graphs": [
                    {
                        "balloonText": "<div style='font-size:14px; color:#2f2f2f;'><strong><span style='color:#2f2f2f'>[[title]]</span> [[value]]</strong></div>",
                        "columnWidth": 0,
                        "cornerRadiusTop": 47,
                        "fixedColumnWidth": 0,
                        "fontSize": 0,
                        "gapPeriod": 1,
                        "id": "g1",
                        "legendAlpha": 1,
                        "lineColor": "#055a60",
                        "lineThickness": 2,
                        "markerType": "circle",
                        "negativeFillAlphas": 0,
                        "negativeLineAlpha": 0,
                        "title": "Fund Return",
                        "useLineColorForBulletBorder": true,
                        "valueField": "value"
                    }
                ],
                "guides": [],
                "valueAxes": [
                    {
                        "id": "v1",
                        "axisAlpha": 0
                    }
                ],

                "allLabels": [],
                "balloon": {
                    "borderThickness": 2,
                    "fillAlpha": 1,
                    "shadowAlpha": 0,
                    "horizontalPadding": 10,
                    "verticalPadding": 8
                },

                "legend": {
                    "enabled": true,
                    "autoMargins": false,
                    "left": 50,
                    "bottom": 0,
                    "marginLeft": 15,
                    "marginRight": 50,
                    "marginBottom": 10,
                    "position": "bottom",
                    "valueWidth": 50,
                    "verticalGap": 5,
                    "color": "rgb(74, 74, 74)"
                },

                "responsive": {
                    "enabled": true,
                    "rules": [
                      // at 400px wide, we hide legend
                      {
                          "maxWidth": 700,
                          "overrides": {
                              "chartScrollbar": {
                                  "enabled": false
                              }
                          }
                      },

                    ]
                },

                "titles": [],

                "dataProvider": graphData[0]
            });

            var yAxis2 = new AmCharts.ValueAxis();
            yAxis2.position = "left"; // set this to "right" if you want it on the right
            yAxis2.offset = 30;
            chart.addValueAxis(yAxis2);

            // graph #2
            var performance = targetElem.dataset.performance;
            var fundsToChangeGraphTitles = new Array("PGVF", "PEPI", "PGHY", "PEPCT");
            var graphTitle = "Benchmark";
            for (var i = 0; i < fundsToChangeGraphTitles.length; i++) {
                if (performance === fundsToChangeGraphTitles[i]) {
                    graphTitle = "Category Average";
                }
            }
            var graph2 = new AmCharts.AmGraph();
            graph2.lineColor = "#65b4e5";
            graph2.lineThickness = 2;
            graph2.balloonText = "<div style='font-size:14px; color:#2f2f2f;'><strong><span style='color:#2f2f2f'>[[title]]</span> [[value]]</strong></div>";
            graph2.xField = "bx";
            graph2.yField = "by";
            graph2.dataProvider = graphData[1];
            graph2.valueField = "value";
            graph2.yAxis = yAxis2; // we need to assign at least one graph to a value axis or it's not shown
            graph2.title = graphTitle;
            graph2.markerType = "circle";
            graph2.legendAlpha = 1;

            chart.addGraph(graph2);

            $("a[href='http://www.amcharts.com/javascript-charts/']").hide();

        }
    };

    function push(date, value, data) {
        data.push({
            "date": new Date(date),
            "value": value
        });

        return data;
    }

    function rebaseData(data) {
        var tempData = $.extend(true, [], data);
        //        var chartMaxY = 6;
        //        var chartMinY = 5;

        var unfilteredData = tempData;
        var filteredData = [];

        var baseValue = 100;
        for (var point in unfilteredData) {
            if (point != "0") {
                var fund = unfilteredData[point].value;
                wasNeg = false;
                if (fund < 0) {
                    fund = fund.replace('-', '');
                    wasNeg = true;
                }
                var fundValue = parseFloat(fund) / parseFloat(100);
                fundValue = precise_round(fundValue, 6);
                fund = wasNeg == true ? baseValue * (1 - parseFloat(fundValue)) : baseValue * (1 + parseFloat(fundValue));
                baseValue = precise_round(fund, 6);
                unfilteredData[point].value = precise_round(baseValue, 2);
            }
            filteredData.push(unfilteredData[point]);
        }
        tempData = filteredData;
        return tempData;
    };

    function precise_round(num, decimals) {
        return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }


    var getDate = function (year) {
        if (year == null)
            year = 0;

        var date = new Date();
        date.setYear(date.getFullYear() - year);

        return date;
    }

    function plotGraphByDate(years) {
        var minDate = new Date(new Date(masterData[0][masterData[0].length - 1].Date).setYear(new Date(masterData[0][masterData[0].length - 1].Date).getFullYear() - years));
        var gMinDate = new Date(minDate.setMonth(minDate.getMonth() - 1))
        var maxDate = new Date(masterData[0][masterData[0].length - 1].Date);
        var funds = [];
        $.each(masterData[0], function (k, v) {
            if (new Date(v.Date) >= minDate && new Date(v.Date) <= maxDate) {
                if (funds.length == 0)
                    funds.push({ "date": gMinDate, "value": 100 });
                else
                    funds.push({ "date": new Date(v.Date), "value": v.Value });
            }
        });

        plot(funds);
    }




})();